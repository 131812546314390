<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/daiban/confirm/index.vue
 * @Author: 张兴业
 * @Date: 2021-11-11 16:08:27
 * @LastEditors: zxy
 * @LastEditTime: 2021-12-22 16:59:16
-->
<template>
  <div class="confirm">
    <van-nav-bar title="确认账号信息" />
    <div>
      <van-cell-group :border="false">
        <van-cell title="绑定手机：" :value="detail.phone" :border="false" />
        <van-cell title="推介人：" :value="detail.agencyName" :border="false" />
        <van-cell
          title="登记姓名："
          :value="detail.idcardName"
          :border="false"
        />
        <van-cell
          title="登记人身份证："
          :value="detail.idcardNumber"
          :border="false"
        />
        <van-cell title="店铺名：" :value="detail.shopName" :border="false" />
        <van-cell
          title="营业执照："
          :value="detail.businessLicense"
          :border="false"
        />
        <van-cell
          title="经营期限："
          :value="detail.operatingPeriod"
          :border="false"
        />
        <van-cell
          title="店铺地址："
          :value="
            `${detail.provinceName} ${detail.cityName} ${detail.districtName} ${detail.address}`
          "
          :border="false"
        />
        <van-cell title="店铺邮箱：" :value="detail.email" :border="false" />
        <van-cell title="店铺证件" :border="false" />
        <div class="pics">
          <div
            class="pic_item"
            @click="preview([detail.businessLicenseUrl], 0)"
          >
            <div class="pic_back">
              <img :src="detail.businessLicenseUrl" alt="" />
            </div>
          </div>
        </div>
        <van-cell title="身份证照片" :border="false" />
        <div v-if="detail.id" class="pics">
          <div
            class="pic_item"
            @click="preview([detail.idcardFrontUrl, detail.idcardBackUrl], 0)"
          >
            <div class="pic_back">
              <img :src="detail.idcardFrontUrl" alt="" />
            </div>
          </div>
          <div
            class="pic_item"
            @click="preview([detail.idcardFrontUrl, detail.idcardBackUrl], 1)"
          >
            <div class="pic_back">
              <img :src="detail.idcardBackUrl" alt="" />
            </div>
          </div>
        </div>

        <van-cell title="门店实景图" :border="false" />
        <div class="pics">
          <div
            v-for="(url, index) in detail.shopImgUrls"
            :key="url"
            class="pic_item"
            @click="preview(detail.shopImgUrls, index)"
          >
            <div class="pic_back">
              <img :src="url" alt="" />
            </div>
          </div>
        </div>
      </van-cell-group>
      <div>
        <div class="agree">
          <agreement v-model="agree" />
        </div>
        <div class="hint">
          请注意，一旦按下确认键完成注册，即代表您知悉并同意您将您的个人资料提供給您所指定或公司安排的推介人，该推介人可能透过您所提供的联系方式主动与您联系。
        </div>
        <div :class="disabledBtn ? 'submit_dis' : 'submit'" @click="submit">
          提交
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
const Agreement = () => import("./components/Agree");

export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    Agreement
  },
  data() {
    return {
      detail: {},
      agree: "0",
      loading: false
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    disabledBtn() {
      // console.log(verifyPhoneWithNoAreaCode(this.phone));
      if (this.agree === "1") {
        return false;
      }
      return true;
    }
  },
  methods: {
    fetchData() {
      const { id } = this.$route.query;
      const url = `/api/front/push/pushClue/loadAgencyInfo/${id}`;
      this.$request(url)
        .then(res => {
          console.log(res);
          if (res.code === 1) {
            this.detail = res.data;
            //数据兼容
            this.detail.data = { shopImgUrls: res.data.shopImgUrls };
          }
        })
        .catch(() => {});
    },
    preview(pics, index) {
      ImagePreview({
        images: pics,
        startPosition: index
      });
    },
    submit() {
      if (this.loading) {
        return;
      }
      if (this.disabledBtn) {
        // this.$toast("您尚未同意用户协议和隐私政策！");
        return;
      }
      this.$request("/api/front/distributorDs/agencyStoreDs", {
        method: "POST",
        data: this.detail
      })
        .then(res => {
          this.loading = false;
          if (res.code === 1) {
            this.$router.push("/download/app/distributor");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.confirm {
  background: white;

  ::v-deep .van-nav-bar {
    background: rgba(253, 40, 36, 0.05);
    font-size: 16px;
    font-weight: 500;
  }

  ::v-deep .van-cell__title {
    text-align: left;
  }

  .pics {
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px;

    .pic_item {
      width: calc(50vw - 12px);

      .pic_back {
        text-align: center;
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 60%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 90%;
        height: 90%;
        max-width: 90%;
        max-height: 90%;
        margin-top: 5%;
        margin-left: 5%;
      }
    }
  }

  .agree {
    padding: 32px;
  }

  .hint {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 36px;
    text-align: left;
  }

  .submit {
    margin: 40px 12px 24px 12px;
    background: linear-gradient(90deg, #fd2824, #fe3d6c 100%);
    font-weight: 500;
    font-size: 14px;
    color: white;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
  }

  .submit_dis {
    margin: 40px 12px 24px 12px;
    background: linear-gradient(90deg, #fd2824, #fe3d6c 100%);
    font-weight: 500;
    font-size: 14px;
    color: white;
    cursor: pointer;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    opacity: 0.4;
  }
}
</style>
